import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/GetStarted";
// import Footer from "components/footers/FiveColumnWithBackground.js";
import Footer from 'components/footers/MiniCenteredFooter.js';
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
// import Features from "components/features/ThreeColSimple.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Features from "components/features/DashedBorderSixFeatures";

// import chefIconImageSrc from "images/mobile-app.svg";
// import celebrationIconImageSrc from "images/digital-marketing.svg";
// import shopIconImageSrc from "images/html.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} /> 

      <Features  />
      {/* <Features 
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "UI/UX Desgin",
            description: "Our team consists of highly skilled graphic designers who can translate your vision onto the page, and will then work with you to create the right brochure design for your client. we are the best at what we do",
            // url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "App Development",
            description: "We like our customers to look at us as an extension of their business and the services we offer with the products we build do just that. we are the best at what we do",
            // url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Digital Marketing",
            description: "We are a coolest and skilled team that does exceedingly well to fulfill your software needs through latest Technology ",
            // url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      /> */}

      <Testimonial
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      />
         <ContactUsForm />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We have the best professional marketing people across the globe just to work with you.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
     
  
      <Footer />
    </AnimationRevealPage>
  );
}
